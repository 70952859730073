import { graphql, Link, navigate } from "gatsby"
import React, { useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {
  ArrowDownIcon,
  ArrowLongRightIcon,
  ArrowRightIcon,
  BellIcon,
  XMarkIcon,
  PlayIcon,
} from "@heroicons/react/24/solid"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import CsrSlider from "../components/sliders/csr-slider"
import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"
import HeroSlider from "../components/sliders/hero-slider"
import CavingSlider from "../components/sliders/caving-slider"
import AudioPlayer from "../components/audioplayer"
import ReactAudioPlayer from "react-audio-player"
import Map from "../components/map"
import Location from "../components/locations"

const InnerPage = ({ data }) => {
  const page = data.dataJson

  const [currentPageIndex, setCurrentPageIndex] = useState(0)

  const social = [
    {
      icon: "fb",
    },
    {
      icon: "pinterest",
    },
    {
      icon: "twitter",
    },
    {
      icon: "whatsapp",
    },
    {
      icon: "copy",
    },
  ]

  const innerPages = [
    {
      id: "01",
      location: "පැරණි ධාතු මන්දිරය",
      link: "/sadaham-sathkara/mihinthalaya/01/",
    },
    {
      id: "02",
      location: "සේල චෛත්‍යය ",
      link: "/sadaham-sathkara/mihinthalaya/02/",
    },
    {
      id: "03",
      location: "සන්නිපාත ශාලාව ",
      link: "/sadaham-sathkara/mihinthalaya/03/",
    },
    {
      id: "04",
      location: "අරාධනා ගල ",
      link: "/sadaham-sathkara/mihinthalaya/04/",
    },
    {
      id: "05",
      location: "නාග පොකුණ ",
      link: "/sadaham-sathkara/mihinthalaya/05/",
    },
    {
      id: "06",
      location: "ගිරිභණ්ඩ සෑය ",
      link: "/sadaham-sathkara/mihinthalaya/06/",
    },
    {
      id: "07",
      location: "දාන ශාලාව ",
      link: "/sadaham-sathkara/mihinthalaya/07/",
    },
    {
      id: "08",
      location: "ඇත් වෙහෙර ",
      link: "/sadaham-sathkara/mihinthalaya/08/",
    },
    {
      id: "09",
      location: "සිංහ පොකුණ ",
      link: "/sadaham-sathkara/mihinthalaya/09/",
    },
    {
      id: "10",
      location: "කණ්ඨක චේතිය ",
      link: "/sadaham-sathkara/mihinthalaya/10/",
    },
    {
      id: "11",
      location: "අටසැට ලෙන් ",
      link: "/sadaham-sathkara/mihinthalaya/11/",
    },
    {
      id: "12",
      location: "ඉඳිකටු සෑය/ කටු සෑය / ආරාම සංකීර්ණය ",
      link: "/sadaham-sathkara/mihinthalaya/12/",
    },
    {
      id: "13",
      location: "රෝහල් සංකීර්ණය ",
      link: "/sadaham-sathkara/mihinthalaya/13/",
    },
    {
      id: "14",
      location: "කළුදිය පොකුණ ",
      link: "/sadaham-sathkara/mihinthalaya/14/",
    },
    {
      id: "15",
      location: "මිහිඳු ගුහාව ",
      link: "/sadaham-sathkara/mihinthalaya/15/",
    },
    {
      id: "16",
      location: "මිහිඳු සෑය ",
      link: "/sadaham-sathkara/mihinthalaya/16/",
    },
    {
      id: "17",
      location: "මිහින්තලා මහා සෑය ",
      link: "/sadaham-sathkara/mihinthalaya/17/",
    },
  ]

  const goToNextPage = () => {
    const activePage = parseInt(page.slug)

    const nextPageIndex = activePage
    if (nextPageIndex < innerPages.length) {
      setCurrentPageIndex(nextPageIndex)
      navigate(innerPages[nextPageIndex].link)
    }
  }

  const goToPreviousPage = () => {
    const activePage = parseInt(page.slug)

    const previousPageIndex = activePage - 2
    if (previousPageIndex >= 0) {
      setCurrentPageIndex(previousPageIndex)
      navigate(innerPages[previousPageIndex].link)
    }
  }

  const getPreviousPageTitle = () => {
    const activePage = parseInt(page.slug)

    const previousPageIndex = activePage - 2
    if (previousPageIndex >= 0) {
      return innerPages[previousPageIndex].location
    }
    return ""
  }

  const getNextPageTitle = () => {
    const activePage = parseInt(page.slug)
    const nextPageIndex = activePage
    if (nextPageIndex < innerPages.length) {
      return innerPages[nextPageIndex].location
    }
    return ""
  }

  return (
    <>
      <Layout>
        <div>
          <div className="">
            <BackgroundImage
              {...convertToBgImage(getImage(data.dataJson.header))}
              className=" w-full"
            >
              <div className="bg-black/70 pt-60">
                <div className="container mx-auto sm:px-20 px-10 h-full pb-20">
                  <div className="flex h-full items-end">
                    <div>
                      <div className="sm:text-6xl text-4xl font-bold mb-4  text-white max-w-lg ">
                        <div>{page.title}</div>
                        <div className="text-4xl">
                          {page.title2 && page.title2}
                        </div>
                      </div>
                      <div className="  text-white text-lg">
                        මෙම ස්ථානය පිලිබදව සිංහල බසින් පහතින් ශ්‍රවනය කරන්න
                      </div>
                      <div className="mt-4">
                        <ReactAudioPlayer src={page.audio} autoPlay controls />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </BackgroundImage>
          </div>
        </div>
        {/* bottom half */}
        <div className="container mx-auto md:px-20 px-10  py-10 md:my-20  ">
          <div className="flex lg:flex-row flex-col gap-7 ">
            <div className="w-full">
              <div className=" ">
                <div className=" text-[#272621]">
                  <div>{page.para1}</div>

                  <div className="pt-3">{page.para2}</div>
                </div>
              </div>

              {/* <div className="pb-8 flex md:flex-row flex-col gap-4">
                <GatsbyImage
                  image={page.image1.childImageSharp.gatsbyImageData}
                  className="w-full rounded-3xl"
                />
                <GatsbyImage
                  image={page.image2.childImageSharp.gatsbyImageData}
                  className="w-full rounded-3xl"
                />
              </div> */}

              <div className=" text-[#272621]  pt-3">{page.para3}</div>

              {/* <div className="border-y-2   border-black		 py-6  	mt-20">
                <div className="flex gap-6  items-center ">
                  <div className="mt-2 sm:block hidden">
                    <StaticImage
                      src="../images/pages/sadaham-sathkara/Tea/Vector.png"
                      className=""
                      alt="share"
                    />
                  </div>
                  <div className="">Share: </div>
                  <div>
                    <div className="flex gap-5">
                      {social.map((item, index) => {
                        return (
                          <Link to="/">
                             <GatsbyImage
                              image={images.fb}
                              className="sm:w-10 w-5 cursor-pointer"
                            /> 
                            <GatsbyImage
                              image={getImage(
                                data.allFile.nodes.find(
                                  node => node.name === item.icon
                                )
                              )}
                              alt={item.alt}
                              className="sm:w-10 w-5 cursor-pointer"
                            />
                          </Link>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="py-14">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-3">
                    <div
                      className="w-16 h-16 rounded-full bg-yellow flex items-center justify-center cursor-pointer"
                      onClick={goToPreviousPage}
                    >
                      <PlayIcon className="text-white w-8 rotate-180" />
                    </div>

                    <div className="flex flex-col">
                      <div className="uppercase font-light ">පෙර ලිපිය</div>
                      <div className=" font-semibold xl:block hidden">
                        {getPreviousPageTitle()}
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center gap-3">
                    <div className="flex flex-col text-right">
                      <div className="uppercase font-light ">ඊලග ලිපිය</div>
                      <div className=" font-semibold xl:block hidden">
                        {getNextPageTitle()}
                      </div>
                    </div>

                    <div
                      className="w-16 h-16 rounded-full bg-yellow flex items-center justify-center cursor-pointer"
                      onClick={goToNextPage}
                    >
                      <PlayIcon className="text-white w-8" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="w-1/3 bg-black/60 rounded-xl ">
              <Location />
            </div> */}
          </div>
        </div>
        <Map />
      </Layout>
    </>
  )
}

export const query = graphql`
  query ($slug: String!) {
    dataJson(slug: { eq: $slug }) {
      slug
      header {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      title
      audio
      para1
      para2
      image1 {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      image2 {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      para3
      social {
        link
      }
    }

    allFile(filter: { sourceInstanceName: { eq: "images" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(quality: 100, formats: [AUTO, WEBP])
        }
        name
        publicURL
      }
    }
  }
`
export default InnerPage
